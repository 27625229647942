<script>
  import {
    layoutComputed
  } from "@/state/helpers";
  import {
    SimpleBar
  } from "simplebar-vue3";
  import {
    SlidersIcon
  } from "@zhuowenli/vue-feather-icons";

  export default {
    components: {
      SimpleBar,
      SlidersIcon
    },
    data() {
      return {
        settings: {
          minScrollbarLength: 60,
        },
        siteDomain: process.env.VUE_APP_SITE_URL,
      };
    },
    computed: {
      ...layoutComputed,
      layoutType: {
        get() {
          return this.$store ? this.$store.state.layout.layoutType : {} || {};
        },
      },
    },

    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },

    mounted() {
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
              let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  let aChild = siblingCollapse.parentNode.firstChild;
                  if (aChild.hasAttribute("aria-expanded")){
                    aChild.setAttribute("aria-expanded", "false");
                  }
                  siblingCollapse.classList.remove("show");
                }
              });
            } else {
              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  let aChild = item1.parentNode.firstChild;
                  if (aChild.hasAttribute("aria-expanded")){
                    aChild.setAttribute("aria-expanded", "false");
                  }
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });
            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    },

    methods: {
      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      },

      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add("active");
                parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                  parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                  if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                      "active");
                }
              }
            }
          }
        }, 0);
      },
    },
  };
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarDashboards">
            <i class="ri-dashboard-2-line"></i>
            <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarDashboards">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/dashboard/analytics" class="nav-link custom-abc" data-key="t-analytics">
                  {{ $t("t-analytics") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/dashboard/crm" class="nav-link" data-key="t-crm">
                  {{ $t("t-crm") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/" class="nav-link" data-key="t-ecommerce">
                  {{ $t("t-ecommerce") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/dashboard/crypto" class="nav-link" data-key="t-crypto">
                  {{ $t("t-crypto") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/dashboard/projects" class="nav-link" data-key="t-projects">
                  {{ $t("t-projects") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!-- end Dashboard Menu -->

      </SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarDashboards">
            <SlidersIcon></SlidersIcon>
            <span data-key="t-dashboards"> Tool</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarDashboards">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/tool" class="nav-link custom-abc" data-key="t-analytics">
                  Tool
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/tool/category" class="nav-link custom-abc" data-key="t-analytics">
                  Category
                </router-link>
              </li>
            </ul>
          </div>
        </li>


        <li class="nav-item">
          <a class="nav-link menu-link" href="#emojiMenu" data-bs-toggle="collapse" role="button"
             aria-expanded="false" aria-controls="sidebarDashboards">
            <SlidersIcon></SlidersIcon>
            <span data-key="t-dashboards"> Emoji</span>
          </a>
          <div class="collapse menu-dropdown" id="emojiMenu">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/emoji" class="nav-link custom-abc" data-key="t-analytics">
                  Emoji
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/emoji/category" class="nav-link custom-abc" data-key="t-analytics">
                  Category
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="menu-title">
          <span data-key="t-menu"> Setting</span>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#settingMenu" data-bs-toggle="collapse" role="button"
             aria-expanded="false" aria-controls="sidebarDashboards">
            <SlidersIcon></SlidersIcon>
            <span data-key="t-dashboards"> Setting</span>
          </a>
          <div class="collapse menu-dropdown" id="settingMenu">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/setting/menu" class="nav-link custom-abc" data-key="t-analytics">
                  Menu
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/setting/cache" class="nav-link custom-abc" data-key="t-analytics">
                  Clear cache
                </router-link>
              </li>
              <li class="nav-item">
                <a :href="siteDomain" target="_blank" class="nav-link custom-abc" data-key="t-analytics">
                  Open site
                </a>
              </li>
            </ul>
          </div>
        </li>

      </ul>
    </template>
  </div>
  <!-- Sidebar -->
</template>
