<script>
import {layoutComputed, loaderComputed} from "@/state/helpers";

import Vertical from "./vertical";
import Horizontal from "./horizontal";
import TwoColumns from "./twocolumn";

export default {
    components: {
        Vertical,
        Horizontal,
        TwoColumns
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
        ...loaderComputed
    },

};
</script>

<template>
  <div>
      <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
          <slot />
      </Vertical>

      <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
          <slot />
      </Horizontal>

      <TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType">
          <slot />
      </TwoColumns>
  </div>
  <div class="custom-overlay" v-if="isLoading">
    <div class="overlay__inner">
      <div class="overlay__content">
        <div class="lds-facebook"><div></div><div></div><div></div></div>
      </div>
    </div>
  </div>
</template>
